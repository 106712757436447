<template>
    <div class="">
        
        <div class="bg-blue-900">
            <div class="container text-center mx-auto py-16 text-white max-w-xl">
                <h1 class="font-bold text-6xl  leading-none my-4">
                    {{$t('partners.heading')}}
                </h1>
                <p>
                    {{$t('partners.intro')}}
                </p>
            </div>
        </div>
        <div 
            class="h-64"
            style="background-image: url(https://images.unsplash.com/photo-1560264280-88b68371db39?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80); background-size: cover; background-position: bottom 40% center;">
        </div>
        
        <div class="bg-gray-100">
            <div class="container mx-auto py-24">
                
                <div class="grid grid-cols-3 gap-8">
                    <!-- <div class="p-16 rounded-xl shadow-xl border border-gray-300 my-12"> -->
                    <div 
                        class="rounded-xl bg-white overflow-hidden hover:shadow-xl"
                        v-for="(partner, i) in partners"
                        :key="'partner_'+i">
                        
                        <div class="px-8 py-12 flex justify-center ">
                            <img :src="partner.logo" alt="" class="w-48 h-12 " style="object-fit: contain;">
                        </div>
                        <div class="p-8 border-t">
                            <h3 class="h2 font-bold text-blue-900">{{partner.name}}</h3>
                            <p class="mt-2">{{partner.description}}</p>
                        </div>
                        <div class="p-8 border-t leading-loose">
                            <div class="">
                                <strong class="w-32 inline-block">Country: </strong>
                                {{partner.country}}
                            </div>
                            <div class="">
                                <strong class="w-32 inline-block">Phonenumber: </strong>
                                {{partner.phone}}
                            </div>
                            <a 
                                class=""
                                :href="'mailto:'+partner.email">
                                <strong class="w-32 inline-block">Email: </strong>
                                {{partner.email}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
        
        <div class="">
            <div class="container mx-auto py-24">
                
                <div class="grid grid-cols-2 gap-16">
                    <div class="">
                        <div class="max-w-lg">
                            <h1 class="font-bold text-6xl text-blue-900 leading-none my-12">
                                {{$t('partners.join.heading')}}
                            </h1>
                            <p>
                                {{$t('partners.join.text')}}
                            </p>
                        </div>
                    </div>
                    
                                
                    <form 
                        v-if="!status"
                        @submit.prevent="submitForm()">
                    
                        <select v-model="form.service">
                            <option :value="null" disabled>{{$t('partners.join.serviceToProvide')}}</option>
                            <option 
                                v-for="(service, i) in services" 
                                :key="'service_'+i" 
                                :value="service">
                                
                                {{$t('partners.join.services.'+service)}}
                            </option>
                        </select>
                        
                        <input 
                            v-model="form.firstName"
                            type="text"
                            :placeholder="$t('general.firstName')"
                        />
                        
                        <input 
                            v-model="form.lastName"
                            type="text"
                            :placeholder="$t('general.lastName')"
                        />
                        
                        <input 
                            v-model="form.companyEmail"
                            type="email"
                            :placeholder="$t('general.companyEmail')"
                        />
                        
                        <input 
                            v-model="form.companyName"
                            type="text"
                            :placeholder="$t('general.companyName')"
                        />
                        
                        <input 
                            v-model="form.companyWebsite"
                            type="text"
                            :placeholder="$t('general.companyWebsite')"
                        />
                        
                        <button type="submit" class="bg-yellow-600 px-4 py-3 text-white rounded">
                            {{$t('partners.join.letsTalk')}}
                        </button>
                    </form>
                    <div 
                        v-else
                        class="py-8 text-left">
                        
                        <i class="fas fa-thumbs-up text-yellow-600 text-5xl" />
                    </div>
                </div>
            </div>
        </div>
        
        
        
    </div>
</template>


<script>
export default {
    data() {
        return {
            services: [
                'technical',
                'marketingAndAdvertising',
                'selling',
                'other',
            ],
            form: {
                formType: 'partner',
                service: null,
                companyName: '',
                companyEmail: '',
                companyPhone: '',
                companyWebsite: '',
            },
            status: null,
            partners: [
                {
                    name: 'Onitio',
                    description: 'Onitio deliver solutions within retail, pharmacy, transport and logistics, and associated sectors. Consultant services with cutting-edge expertise within retail and IT is provided through Value Retail Consulting.',
                    logo: '/images/partners/onitio.svg',
                    phone: '+47 980 60 000',
                    email: 'sales.solutions.no@onitio.com',
                    country: 'Norway',
                },
                {
                    name: 'Digiprom',
                    description: 'Totalleverandør innen digital skilting. Digiprom har lang erfaring og kompetanse med hardware tilpasset proffmarkedet. Kvalitet, lysstyrke, ute og inne skjerm, med og uten touch, ja det finnes utallige varianter.',
                    logo: '/images/partners/digiprom2.png',
                    phone: '+47 400 04 244',
                    email: 'post@digiprom.no',
                    country: 'Norway',
                },
                {
                    name: 'Ditt Grafisk',
                    description: 'Et svært fleksibelt reklame- profil og produksjonsbyrå, med et komplett tjenestespekter på veien til økt synlighet og vekst. Daglig hjelper vi både små og store bedrifter å bli synlig, i form av grafisk design, reklame, dekor og skiltløsninger.',
                    logo: '/images/partners/dittgrafisk.png',
                    phone: '+47 33 74 04 04',
                    email: 'post@dittgrafisk.no',
                    
                    country: 'Norway',
                }
            ]
            
        }
    },
    methods: {
        async submitForm(){
            const response = await this.$axios.post('https://9qqwueeee8.execute-api.eu-west-1.amazonaws.com/prod/form', this.form);
            console.log(response);
            this.status = response;
        }
    },
}
</script>

<style lang="scss" >
</style>
